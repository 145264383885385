// Overrides default rails confirmation with bootstrap 4 confirm dialog
Rails.confirm = function(message, element) {
  let $element = $(element)
  let $dialog = $('#confirm-modal')
  let $content = $dialog.find('#modal-content')
  let $ok = $dialog.find('#ok-button')

  $content.html(message)

  $ok.click(function(event) {
    event.preventDefault()
    $dialog.modal("hide")
    let old = Rails.confirm
    Rails.confirm = function() { return true }
    $element.get(0).click()
    Rails.confirm = old
  })

  $dialog.modal("show")
  return false;
}
