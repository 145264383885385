// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'bootstrap'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import $ from 'jquery';
global.$ = jQuery;

import Rails from "@rails/ujs";
window.Rails = Rails;

require("@vimeo/player")
import Player from "@vimeo/player";
window.Player = Player;

require('datatables.net-bs4');
require('datatables.net-responsive-bs4');
require('datatables.net-buttons-bs4');
require('datatables.net-buttons/js/buttons.html5.js');
require('bootstrap-select');
require('bootstrap-duallistbox/dist/jquery.bootstrap-duallistbox.min');
require('jquery-countdown');
require("jquery-validation");

// all required files will be used in ALL pages
// for page-specific javascript, put them in app/assets/javascripts and update app/assets/config/manifest.js
require("./nested-forms/add_fields");
require("./nested-forms/remove_fields");
require("./nested-forms/nested_form");
require("./script");
require("./input");
require("./confirm");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
